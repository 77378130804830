import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import * as packageJson from '../package.json';
import Playground from '../../../src/Playground';
import { UiConfirmDialog } from '../src';
import { ConfirmDialogExample } from './example';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1 {...{
      "id": "uiconfirmdialog"
    }}>{`UiConfirmDialog`}</h1>
    <sup>
  <a href="https://github.com/inavac182/ui-react/blob/main/packages/COMPONENT-NAME/" target="_blank">
    v{packageJson.version}
  </a>
    </sup>
    <blockquote>
      <p parentName="blockquote">{`DESCRIPTION`}</p>
    </blockquote>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <blockquote>
      <p parentName="blockquote">{`npm i @uireact/foundation @uireact/confirm-dialog`}</p>
    </blockquote>
    <h2 {...{
      "id": "uiconfirmdialog-1"
    }}>{`UiConfirmDialog`}</h2>
    <p>{`In order to use this component you should render the component `}<inlineCode parentName="p">{`<UiConfirmDialog />`}</inlineCode>{` inside your `}<inlineCode parentName="p">{`UiView`}</inlineCode>{` wrapper component. Only 1
component should be rendered in the react tree.`}</p>
    <h3 {...{
      "id": "useconfirmdialog-hook"
    }}>{`useConfirmDialog hook`}</h3>
    <p>{`The confirm dialog content is passed through the function `}<inlineCode parentName="p">{`showConfirmDialog`}</inlineCode>{` that you can retrieve using the `}<inlineCode parentName="p">{`useConfirmDialog`}</inlineCode>{` hook. Look
at the example below to understand how this works:`}</p>
    <h3 {...{
      "id": "showconfirmdialog-fn"
    }}>{`showConfirmDialog() fn`}</h3>
    <p>{`This function receives 4 parameters:`}</p>
    <ol>
      <li parentName="ol">{`data, The confirm dialog data that renders inside the dialog.`}</li>
      <li parentName="ol">{`onConfirm, Callback executed if user click on the confirm action.`}</li>
      <li parentName="ol">{`onDeny, Callback executed if user click on the denial action.`}</li>
      <li parentName="ol">{`options (optional), The options for the confirm dialog.`}</li>
    </ol>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <Playground __position={1} __code={'<ConfirmDialogExample />'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiConfirmDialog,
      ConfirmDialogExample,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ConfirmDialogExample mdxType="ConfirmDialogExample" />
    </Playground>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`export const ConfirmDialogExample: React.FC = () => {
  const { showConfirmDialog } = useConfirmDialog();

  const onConfirm = useCallback(() => {
    console.log('Action Confirmed');
  }, []);
  const onDeny = useCallback(() => {
    console.log('Action denied');
  }, []);
  const onClick = useCallback(() => {
    showConfirmDialog(
      {
        title: 'Are you sure?',
        message: 'Only accept this if you are completely sure',
        confirmLabel: 'Accept',
        denyLabel: 'Cancel',
      },
      onConfirm,
      onDeny,
      {
        direction: 'stacked',
      }
    );
  }, [showConfirmDialog]);

  return (
    <div>
      <UiButton onClick={onClick}>Open confirm dialog</UiButton>
      <UiConfirmDialog />
    </div>
  );
};
`}</code></pre>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <Props of={UiConfirmDialog} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      